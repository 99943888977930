'use client';
import { useRouter } from 'next/router';
import styles from './404.module.css';
import { Button } from '@airbus/components-react';
import { t } from '../i18next';

export default function Custom404() {
  const router = useRouter();

  return (
    <div className={styles.error_page}>
      <div className={styles.liheight}>
        <h1 className={styles.page_not_found}>404</h1>
        <div className={styles.setInline}>
          <h2 className={styles.subTitle}>{t('404.no_access')}</h2>
        </div>
      </div>
      <Button variant='primary' onClick={() => router.push('/')}>
        {t('404.go_home')}
      </Button>
    </div>
  );
}
